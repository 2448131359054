'use client'

import { useState, useRef, useEffect } from "react"
import { useRouter, usePathname } from 'next/navigation'
import { useLocale, useTranslations } from 'next-intl'
import Link from 'next/link'
import { useAuth } from '@clerk/nextjs'
import { Sheet, SheetContent, SheetDescription, SheetTitle, SheetTrigger } from "@/components/ui/sheet"
import { UserRound, ShoppingBag, Menu, ChevronDown, ChevronUp } from "lucide-react"
import * as VisuallyHidden from '@radix-ui/react-visually-hidden'
import { IconWithTooltip } from "@/components/shared/IconWithTooltip"
import { useRTLAwareStyle } from "@/util/rtl"
import Image from 'next/image'
import { motion, AnimatePresence } from 'framer-motion'
import { useInView } from "@/hooks/useInView"

// Import your Luvi logo SVG
import LuviLogo from '../shared/icons/Logo'
import Bouq from '@/public/images/charming-bouquet-2.jpg'
import Bloom from '@/public/images/bloom.jpg'
import RedRomance from '@/public/images/red-romance-2.jpg'
import Boxbeauty from '@/public/images/boxed-beauties-2.jpg'
import Sweatheart from '@/public/images/sweetheart-box-2.jpg'


interface Props {
  lang: string
}

export default function Header({lang}:Props) {
  const [hoveredItem, setHoveredItem] = useState<string | null>(null)
  const [isMenuVisible, setIsMenuVisible] = useState(false)
  const menuTimeoutRef = useRef<NodeJS.Timeout | null>(null)
  const router = useRouter()
  const pathname = usePathname()
  const locale = useLocale()
  const { isSignedIn } = useAuth()
  const t = useTranslations('common')
  const rtlAwareStyle = useRTLAwareStyle('left-2', 'right-2')
  const letterSpacing = useRTLAwareStyle('tracking-wider', '')

  const { ref, isInView } = useInView()

  const icons = [
    { icon: <UserRound className="w-5 h-5 text-gray-700" />, text: t('header.account') },
    { icon: <ShoppingBag className="w-5 h-5 text-gray-700" />, text: t('header.viewCart'), badge: true }
  ]

  const navItems = [
    { name: t('nav.mothersDay'), href: `/${lang}/mothers-day`},
    // { name: t('nav.womensday'), href: `/${lang}/womens-day`},

    { name: t('nav.bouquets'), href: `/${lang}/charming-bouquets`, megaMenu: true },
    { name: t('nav.arrangements'), href: `/${lang}/boxed-beauties`, megaMenu: true },
    { name: t('nav.acrylicFlorals'), href: `/${lang}/acrylic-florals` },
    { name: t('nav.bloomingVases'), href: `/${lang}/blooming-vases` },
    { name: t('nav.hampers'), href: `/${lang}/hampers-and-bundles` },
    { name: t('nav.ramadan'), href: `/${lang}/ramadan-collection` }
    
  ]

  const switchLocale = (newLocale: string) => {
    if (newLocale !== locale) {
      const newPathname = pathname.replace(`/${locale}`, '') || '/'
      router.push(`/${newLocale}${newPathname}`)
    }
  }

  const handleAccountClick = () => {
    if (isSignedIn) {
      router.push('/account/overview')
    } else {
      router.push('/sign-in')
    }
  }

  const handleMouseEnter = (itemName: string) => {
    if (menuTimeoutRef.current) {
      clearTimeout(menuTimeoutRef.current)
    }
    setHoveredItem(itemName)
    setIsMenuVisible(true)
  }

  const handleMouseLeave = () => {
    menuTimeoutRef.current = setTimeout(() => {
      setHoveredItem(null)
      setIsMenuVisible(false)
    }, 300)
  }

  const handleMegaMenuLinkClick = () => {
    setHoveredItem(null)
    setIsMenuVisible(false)
  }

  useEffect(() => {
    return () => {
      if (menuTimeoutRef.current) {
        clearTimeout(menuTimeoutRef.current)
      }
    }
  }, [])

  return (
    <motion.header
      ref={ref}
      initial={{ opacity: 0.70 }}
      animate={isInView ? { opacity: 1 } : {}}
      transition={{ duration: 0.6, ease: 'easeOut' }}
      className="border-b w-full relative bg-white"
    >
      <div className="flex flex-col justify-center gap-5 w-full md:container my-4 md:mt-8 md:mb-2">
        {/* Top Header Nav */}
        <div className="flex px-4 md:px-2 items-center w-full">
          {/* Left Nav (mobile menu on small screens) */}
          <div className="flex-1 flex md:w-full">
            <Sheet>
              <SheetTrigger className="md:hidden">
                <Menu className="w-5 h-5 text-gray-700" />
              </SheetTrigger>
             
              <VisuallyHidden.Root>
                <SheetTitle>{t('header.menu')}</SheetTitle>
                <SheetDescription>{t('header.menuDescription')}</SheetDescription>
              </VisuallyHidden.Root>
              
              <SheetContent side={locale === 'ar' ? 'right' : 'left'} className="flex flex-col">
                <MobileMenu
                  onLinkClick={() => {
                    document.dispatchEvent(new KeyboardEvent('keydown', {'key': 'Escape'}));
                  }}
                  navItems={navItems}
                  locale={locale}
                  switchLocale={switchLocale}
                />
              </SheetContent>
            </Sheet>
          </div>

          {/* Center Logo */}
          <div className="flex-1 flex justify-center">
            <Link href={`/${lang}`}>
              <LuviLogo/>
              <VisuallyHidden.Root>{t('header.home')}</VisuallyHidden.Root>
            </Link>
          </div>

          {/* Right Nav */}
          <div className="flex-1 flex justify-end items-center gap-5 md:self-start">
            <div className="hidden md:flex">
            <LanguageSwitcher locale={locale} switchLocale={switchLocale} />
            </div>
            {icons.map((item, index) => (
              <IconWithTooltip 
                key={item.text} 
                item={item} 
                index={index}
                handleAccountClick={handleAccountClick}
              />
            ))}
          </div>
        </div>

        {/* Bottom Header Nav */}
        <nav className="hidden md:flex justify-center border-t pt-2">
          <ul className="flex justify-center gap-7">
            {navItems.map((item) => (
              <li 
                key={item.name}
                onMouseEnter={() => handleMouseEnter(item.name)}
                onMouseLeave={handleMouseLeave}
                className=""
              >
                <NavLink href={item.href} isHovered={hoveredItem === item.name} hasMegaMenu={item.megaMenu}>
                  {item.name}
                </NavLink>
                <AnimatePresence>
                  {item.megaMenu && hoveredItem === item.name && (
                    <MegaMenu 
                      item={item} 
                      isVisible={isMenuVisible} 
                      onLinkClick={handleMegaMenuLinkClick}
                    />
                  )}
                </AnimatePresence>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </motion.header>
  )
}

function NavLink({ href, children, isHovered, hasMegaMenu }: { href: string, children: React.ReactNode, isHovered: boolean, hasMegaMenu: boolean |undefined }) {
  const rtlAwareStyle = useRTLAwareStyle('left-0', 'right-0')
  const letterSpacing = useRTLAwareStyle('tracking-wider', '')

  return (
    <Link href={href} className="group inline-flex items-center cursor-pointer">
      <span className={`relative z-10 transition-all duration-300 ease-in-out font-medium text-lg ${letterSpacing} text-black hover:text-[#bcb398]`}>
        {children}
        <span className={`absolute bottom-0 ${rtlAwareStyle} w-0 h-0.5 bg-[#bcb398] transition-all duration-300 ease-in-out ${isHovered ? 'w-full' : ''}`}></span>
      </span>
    </Link>
  )
}

function LanguageSwitcher({ locale, switchLocale }: { locale: string, switchLocale: (newLocale: string) => void }) {
  const t = useTranslations('common')
  const rtlAwareStyle = useRTLAwareStyle('flex-row', 'flex-row-reverse')
  const rtlAwarePadding = useRTLAwareStyle('pr-1', 'pl-1')

  return (
    <div className={`flex items-center border rounded-full overflow-hidden ${rtlAwareStyle}`}>
      <button 
        onClick={() => switchLocale('en')}
        className={`px-2 py-1 text-sm transition-colors ${
          locale === 'en' 
            ? 'bg-[#bcb398] text-white font-medium' 
            : 'text-gray-600 hover:text-[#bcb398]'
        }`}
        disabled={locale === 'en'}
      >
        EN
      </button>
      <div className={`h-4 w-px bg-gray-300 mx-1 ${rtlAwarePadding}`} />
      <button 
        onClick={() => switchLocale('ar')}
        className={`px-2 py-1 text-sm transition-colors ${
          locale === 'ar' 
            ? 'bg-[#bcb398] text-white font-medium' 
            : 'text-gray-600 hover:text-[#bcb398]'
        }`}
        disabled={locale === 'ar'}
      >
        عربي
      </button>
    </div>
  )
}

function MegaMenu({ item, isVisible, onLinkClick }: { 
  item: { name: string; href: string }, 
  isVisible: boolean,
  onLinkClick: () => void
}) {
  const t = useTranslations('common')
  const letterSpacing = useRTLAwareStyle('tracking-wider', '')
  const icon = useRTLAwareStyle('', 'rotate-180')
  const translate = useRTLAwareStyle('group-hover:translate-x-1', 'group-hover:-translate-x-1')

  const menuItems = {
    [t('nav.bouquets')]: [
      { title: t('nav.charmingBlooms'), href: '/charming-blooms', image: Bloom },
      { title: t('nav.charmingBouquets'), href: '/charming-bouquets', image: Bouq },
      { title: t('nav.redRomance'), href: '/red-love', image: RedRomance },
    ],
    [t('nav.arrangements')]: [
      { title: t('nav.boxedBeauties'), href: '/boxed-beauties', image: Boxbeauty },
      { title: t('nav.sweetheartBox'), href: '/boxed-beauties', image: Sweatheart },
    ],
  }

  const currentItems = menuItems[item.name as keyof typeof menuItems] || []

  return (
    <motion.div 
      initial={{ height: 0, opacity: 0 }}
      animate={{ height: 'auto', opacity: 1 }}
      exit={{ height: 0, opacity: 0 }}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
      className="absolute left-0 w-full bg-white shadow-lg z-50 border-t overflow-hidden"
      style={{ top: '100%' }}
    >
      <div className="container mx-auto px-4 py-8">
        <ul className="grid gap-6 md:grid-cols-3">
          {currentItems.map((menuItem, index) => (
            <li key={index} className="col-span-1">
              <Link href={menuItem.href} className="group block h-full" onClick={onLinkClick}>
                <div className="relative aspect-[9/9] w-full overflow-hidden rounded-lg">
                  <Image
                    src={menuItem.image}
                    alt={menuItem.title}
                    fill
                    sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                    className="object-cover transition-transform duration-300 group-hover:scale-105"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent transition-opacity duration-300 group-hover:opacity-80" />
                  <div className="absolute inset-0 p-6 flex flex-col justify-end">
                    <h3 className={`text-2xl font-medium text-white mb-2 ${letterSpacing}`}>
                      {menuItem.title}
                    </h3>
                    <span className={`inline-flex items-center text-sm font-medium text-white ${letterSpacing}`}>
                      {t('nav.learnMore')}
                      <svg 
                        className={`w-4 h-4 ml-2 ${translate} transition-transform duration-300 ${icon}`} 
                        fill="none" 
                        viewBox="0 0 24 24" 
                        stroke="currentColor"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                      </svg>
                    </span>
                  </div>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </motion.div>
  )
}

function MobileMenu({ navItems, locale, switchLocale, onLinkClick }: { 
  navItems: Array<{ name: string; href: string; megaMenu?: boolean }>, 
  locale: string, 
  switchLocale: (newLocale: string) => void,
  onLinkClick: () => void
}) {
  const t = useTranslations('common')
  const [expandedItem, setExpandedItem] = useState<string | null>(null)
  const letterSpacing = useRTLAwareStyle('tracking-wider', '')
  const menuItems = {
    [t('nav.bouquets')]: [
      { title: t('nav.charmingBouquets'), href:  '/charming-bouquets', image: Bouq },
      { title: t('nav.redRomance'), href: '/red-romance', image: RedRomance },
    ],
    [t('nav.arrangements')]: [
      { title: t('nav.boxedBeauties'), href: '/boxed-beauties', image: Boxbeauty },
      { title: t('nav.sweetheartBox'), href: '/boxed-beauties', image: Sweatheart },
    ],
  }

  const toggleExpand = (itemName: string) => {
    setExpandedItem(expandedItem === itemName ? null : itemName)
  }

  const handleLinkClick = () => {
    onLinkClick()
  }

  return (
    <div className="flex flex-col h-full">
      <div className="py-4 flex justify-center">
        <LanguageSwitcher locale={locale} switchLocale={switchLocale} />
      </div>
      <nav  className="flex-grow overflow-y-auto">
        <ul className="space-y-4">
          {navItems.map((item) => (
            <li key={item.name} className="border-b border-gray-200 pb-4">
              {item.megaMenu ? (
                <div>
                  <button 
                    onClick={() => toggleExpand(item.name)}
                    className={`flex justify-between items-center w-full py-2 text-left font-medium text-base ${letterSpacing} text-gray-700`}
                  >
                    {item.name}
                    {expandedItem === item.name ? <ChevronUp className="w-5 h-5" /> : <ChevronDown className="w-5 h-5" />}
                  </button>
                  {expandedItem === item.name && (
                    <ul className="mt-2 space-y-4">
                      {menuItems[item.name as keyof typeof menuItems]?.map((subItem, index) => (
                        <li key={index}>
                          <Link 
                            href={subItem.href} 
                            className="flex items-center py-2 pl-4 hover:bg-gray-100"
                            onClick={handleLinkClick}
                          >
                            <div className="relative w-20 h-20 mr-4">
                              <Image 
                                src={subItem.image} 
                                alt={subItem.title} 
                                fill
                                sizes="(max-width: 768px) 100vw, 80px"
                                className="rounded-md object-cover"
                              />
                            </div>
                            <span className="font-medium text-gray-700 hover:text-[#bcb398]">{subItem.title}</span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              ) : (
                <Link 
                  href={item.href} 
                  className={`block py-2 font-medium text-base ${letterSpacing} text-gray-700 hover:text-[#bcb398]`}
                  onClick={handleLinkClick}
                >
                  {item.name}
                </Link>
              )}
            </li>
          ))}
        </ul>
      </nav>
      <div className="py-4 mt-auto">
        <Link 
          href="/contact" 
          className="block py-2 font-medium text-lg text-gray-700 hover:text-[#bcb398]"
          onClick={handleLinkClick}
        >
          {t('header.contactUs')}
        </Link>
      </div>
    </div>
  )
} 